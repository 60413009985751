const API_URL = "https://ai-chat.softint.eu/conversation/";
let conversationId: string | undefined;
const error: string = "OpenAI servers are busy right now! Please, try again.";

type ConversationToken = {
  conversationId: string;
};

export async function getConverstionId(): Promise<void> {
  try {
    let answer: ConversationToken = await fetch(API_URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response: Response) => response.json());
    conversationId = answer.conversationId;
  } catch {}
}

export async function sendMessage(
  question: string,
  setLastMessage: React.Dispatch<React.SetStateAction<string | undefined>>
): Promise<string> {
  if (conversationId === undefined) {
    await getConverstionId();
  }
  if (conversationId) {
    try {
      let response = await fetch(`${API_URL}${conversationId}`, {
        method: "POST",
        body: JSON.stringify({ question }),
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      });
      if (response?.body != null) {
        const reader = response.body
          .pipeThrough(new TextDecoderStream())
          .getReader();
        while (true) {
          const { value, done } = await reader.read();
          if (done) {
            break;
          }
          console.log(value);
          setLastMessage(value);
        }
      } else {
        return error;
      }
      return "";
    } catch {
      return error;
    }
  } else {
    return error;
  }
}
