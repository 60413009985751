import Header from "./components/Header";
import Main from "./components/Main";

function App(): JSX.Element {
   return (
     <>
       <div className="container">
         <Header></Header>
         <Main></Main>
       </div>
     </>
   );
}

export default App;
