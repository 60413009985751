import React from "react";
import  "./style.scss";

function Header(): JSX.Element {
  return (
    <header className="header">
      <h1>SI - CHAT</h1>
    </header>
  );
}

export default Header;
