import React, { useEffect, useRef, useState } from "react";
import "./style.scss";

type ConversationProps = {
  lastMessage: string | undefined;
};

function Conversation({ lastMessage }: ConversationProps): JSX.Element {
  const [messages, setMessages] = useState<string[]>([]);
  const conversation = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (conversation.current != null) {
      conversation.current.scrollTop = conversation.current.scrollHeight;
    }
  });

  useEffect(() => {
    if (lastMessage !== undefined) {
      setMessages((msg: string[]) => [...msg, lastMessage]);      
    }
  }, [lastMessage]);

  return (
    <div ref={conversation} className="conversation">
      <span className="intro">Hello, how can I help you?</span>

      {messages.map(
        (value: string, index: number): JSX.Element => (
          <li className="list" key={index}>
            {value}
          </li>
        )
      )}
    </div>
  );
}

export default Conversation;
