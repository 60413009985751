import React, { useState } from "react";
import Conversation from "./Conversation";
import InputQuestion from "./InputQuestion";
import "./style.scss";

function Main(): JSX.Element {
  const [lastMessage, setLastMessage] = useState<string | undefined>(undefined);

  return (
    <div className="main__container">
      <Conversation lastMessage={lastMessage}></Conversation>
      <InputQuestion setLastMessage={setLastMessage}></InputQuestion>
    </div>
  );
}

export default Main;
