import React, { useState } from "react";
import { sendMessage } from "../services/httpService";
import "./style.scss";

type InputQuestionProps = {
  setLastMessage: React.Dispatch<React.SetStateAction<string | undefined>>;
};

function InputQuestion({ setLastMessage }: InputQuestionProps): JSX.Element {
  const [question, setQuestion] = useState<string>("");
  const [canMessageBeSent, setCanMessageBeSent] = useState<boolean>(true);
  const handleMessage = async (): Promise<void> => {
    if (question !== "") {
      setCanMessageBeSent(false);
      setQuestion("");
      setLastMessage(question);
      const answer: string = await sendMessage(question, setLastMessage);
      if (answer !== "") {
        setLastMessage(answer);
      }
      setCanMessageBeSent(true);
    }
  };
  return (
    <div className="footer">
      <input
        value={question}
        placeholder="Send a question..."
        className="input"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setQuestion(e.target.value)
        }
        onKeyDown={(e) => {
          if (e.key === "Enter" && canMessageBeSent) {
            handleMessage();
          }
        }}
      ></input>
      
      <button
        onClick={handleMessage}
        className="material-symbols-outlined send"
      >
        send
      </button>
    </div>
  );
}

export default InputQuestion;
